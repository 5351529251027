
Display a list of trips in a container which automatically scrolls through them, 
while also periodically refreshing the data for newer trips. This scrolling is 
accomplished by having the data duplicated into two components one on top of the other,
and when the bottom component's to edge hits the top of the screen, resetting.

When data is refreshed, if the bottom display is not yet on screen, the data will be 
put into that display to scroll up into view seamlessly. Otherwise the data will wait 
in the nextValues state variable and we'll wait for the reset to come back around.

If the number of trips to show + any attached message fits fully on the screen,
then it will be marked as tooSmallToScroll and there will be no auto-scrolling.

If, when refreshing the data, an emergency message comes back, then the whole
display will be replaced with that emergency message until the refrehed data
no longer returns it.

There seems to be an odd edge case when the scrolling can accelerate over time.
I believe this might be due to multiple callback requests somehow being triggered
at some point. To protect against this possibility, if three data requests in a row
come within a short time period of each other, a new iteration won't be triggered from
the current request.

<template>
    <div class="data-container scrolling-layout" ref="data_container">
        <div v-if="false && tooSmallToScroll" class="data-timer">
            <div class="data-timer-inner"></div>
        </div>
        <div class="data-display" ref="data_display">
            <div ref="single_data" :class="'inner-data' + (tooSmallToScroll ? ' too-small': ' data-flex')">
                <TripDisplay
                    v-bind:key="trip.internal_trip_number + '_' + index + 'top' + lastSwitch" 
                    v-for="(trip, index) in topValues.trips" 
                    v-bind:trip="trip" 
                    v-bind:dataIndex="index"
                    v-bind:query="$props.query"
                    v-bind:hide="isEmergency"
                    :isRail="$props.isRail"
                    @navigate-to-stops="navigateToStops"
                />
                <MessageDisplay v-if="!isEmergency" :key="'message_top'" :message="topValues.message" />
                <div v-else class="emergency_message">
                    <MessageDisplay :key="'message_top'" :message="topValues.message" />
                </div>

                <!-- <div class="cycle-divider" v-if="!isEmergency && (!topValues.message || !topValues.message.length)" /> -->
                <div class="cycle-divider" />

                <div class="no-trips-message" v-if="initialLoadComplete && topValues.trips.length === 0 && !isEmergency">
                    See Schedule for Departures
                </div>

                <div class="no-trips-message" v-if="!initialLoadComplete">
                    <Loader />
                </div>
            </div>
            <div v-if="!tooSmallToScroll" :class="`inner-data data-flex ${hideBottom ? 'hidden': ''}`">
                <TripDisplay 
                    v-bind:key="trip.internal_trip_number + '_' + index + 'bottom' + lastSwitch" 
                    v-for="(trip, index) in bottomValues.trips" 
                    v-bind:trip="trip" 
                    v-bind:query="$props.query"
                    v-bind:hide="isEmergency"
                    :isRail="$props.isRail"
                    @navigate-to-stops="navigateToStops"
                />
                <MessageDisplay v-if="!isEmergency" :key="'message_top'" :message="bottomValues.message" />
                <div v-else class="emergency_message">
                    <MessageDisplay :key="'message_top'" :message="bottomValues.message" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import TripDisplay from "@/data_display/TripDisplay";
    import MessageDisplay from "@/data_display/MessageDisplay";

    import Loader from "@/components/Loader";

    // import { getTrips as getTrips } from "@/requests/DataRequests";
    import { getTripsNEW as getTrips } from "@/requests/DataRequests";

    // this is needed to fully copy the trips data when refreshing/
    // switching the data between the top and bottom displays
    // otherwise there can be potential issues of improper updating
    // due to hanging references
    import cloneDeep from 'lodash.clonedeep';

    const dataRequestInterval = 8000;
    // no autoscrolling by default anymore
    const DEFAULT_SCROLL_SPEED = 0;
    // const DEFAULT_SCROLL_SPEED = 8;

    let globalLast = null;
    let fastStep = false;

    let lanes = {};

    export default {
        name: "ScrollingTripsContainer",
        props: ["query", "isRail"],
        components: {
            TripDisplay,
            MessageDisplay,
            Loader
        },
        data() {
            return {
                scrollSpeed: this.$props.query.scrolling ? this.$props.query.scrolling : DEFAULT_SCROLL_SPEED,
                lastSwitch: null,
                topValues: {
                    trips: [],
                    message: ""
                },
                bottomValues : {
                    trips: [],
                    message: ""
                },
                nextValues: null,
                isEmergency: false,
                top: 0,
                topTimer: null,
                dataTimer: null,
                tooSmallToScroll: null,
                hideBottom: true,
                initialLoadComplete: false
            }
        },
        watch: {
            tooSmallToScroll: function(val) {
                // just stop everything immediately if we're too small to scroll
                if(val){
                    this.top = 0;
                    clearInterval(this.topTimer);
                    this.topTimer = null;
                    // this.$refs.data_display.style.transform = `translateY(${this.top}px)`;
                    console.log("RESET THE TRANSFORM TO ZERO 2");
                    this.$refs.data_display.style.transform = `0px`;
                }
            },
            topValues(){ 
                console.log("FULL CYCLE: TOP VALUES CHANGED"); 
                this.lastSwitch = new Date();
            },
            bottomValues(newBV, oldBV){ 
                console.log("FULL CYCLE: BOTTOM VALUES CHANGED", newBV, oldBV); 
            }
        },
        methods: {
            getData (initial) {

                console.log("GET THE SCROLLING DATA", initial, "a");

                const pageType = this.$route.path.split("/")[1];
                if(pageType === "rail"){
                    this.$props.query.mode = "RAIL";
                }
                else if(pageType === "bus"){
                    this.$props.query.mode = "BUS";
                }

                getTrips(this.$props.query)
                .then((res) => {
                    if(!res || !res.data){
                        // just exit if we don't have a proper response
                        return;
                    }

                    const data = this.runChangeCheck(res.data);

                    this.initialLoadComplete = true;
                    
                    console.log("REQUEST DATA", data);

                    if(this.tooSmallToScroll){
                        this.previouslyScrolling = false;
                    }

                    // put all of the data into a new object for easier usage
                    let tripsToUse = data.DVTrip;

                    tripsToUse = this.filterIncomplete(tripsToUse);

                    console.log("AFTER FILTER", data.DVTrip, tripsToUse);

                    console.log("SCROLLING TRIP QUERY", this.$props.query);

                    if(this.$props.query.rows){
                        let messageCount = (data.message && data.message.message) ? data.message.message.length : 0; 
                        if(!Array.isArray(data.message)){
                            if(data.message){
                                messageCount = 1;
                            }
                            else{
                                messageCount = 0;
                            }
                        }

                        let entries = +this.$props.query.rows - messageCount;
                        if(entries < 1){
                            entries = 1;
                        }
                        console.log("ROWS COUNT MESSAGES", data.message, +this.$props.query.row, messageCount);
                        tripsToUse = tripsToUse.slice(0, entries);
                        console.log("TRIPS TO USE", entries, tripsToUse);
                    }
                    const trips = tripsToUse;
                    // let message = [{message:data.message ? data.message.message : ""}, {message:data.message ? data.message.message : ""}];
                    let message = (data.message && data.message.message) ? data.message.message : ( data.message ? data.message : "");
                    let isEmergency = (data.message && data.message.isEmergency);

                    console.log("MESSAGE HERE", message);

                    const dataConverted = {
                        trips,
                        message,
                        isEmergency
                    };

                    const overallHeight = window.innerHeight;

                    // what we do will be change depending on whether the bottom
                    // data display is in view yet or not
                    const bottomNotShowing = -1* this.top < (this.getSingleDataHeight() - overallHeight);

                    console.log("NEXT", dataConverted);
                    console.log("PER SECOND PIXLES TO SCROLL", overallHeight, this.scrollSpeed);
                    console.log("SIZE COMPARISON", this.top, this.getSingleDataHeight(), overallHeight);
                    console.log("IS BOTTOM NOT SHOWING", bottomNotShowing);

                    // the initial display is a special case
                    if(initial || this.tooSmallToScroll || (this.isEmergency && !isEmergency)){
                        console.log("INITIAL OR EMERGENCY, IMMEDIATELY REPLACED");
                        this.topValues = cloneDeep(dataConverted);
                        this.bottomValues = cloneDeep(dataConverted); 
                    }
                    else if(bottomNotShowing){
                        console.log("BOTTOM DATA ONLY REPLACED");
                        this.bottomValues = cloneDeep(dataConverted); 
                        this.nextValues = cloneDeep(dataConverted); 
                        console.log(this.topValues, this.bottomValues, dataConverted);
                    }
                    else{
                        console.log("NEXT VALUES REPLACED");
                        this.nextValues = cloneDeep(dataConverted); 
                    }

                    this.tooSmallToScroll = false;
                    this.hideBottom = true;

                    // this check is done asynchoronously
                    this.checkTooSmall();

                    this.isEmergency = isEmergency;

                    // if we don't have a repeating data request running already
                    // and we're not too small to scroll
                    // then proceed to the rest of the code
                    if(!this.topTimer && !this.tooSmallToScroll){
                        console.log("NO TOP TIMER HERE");

                        let lastTimeStamp = null;

                        this.topTimer = true;

                        let last = null;

                        // this is the function which handles
                        // moving the display and pushing new data
                        // in for refreshing
                        const makeStep = () => {
                            window.requestAnimationFrame((timestamp) => {
                                if (!last) {
                                    last = timestamp;
                                }
                                const elapsed = timestamp - last;
                                last = timestamp;

                                /*
                                    attempt to combat the seeming acceleration
                                    
                                    my only though is that there must be extra calls being picked up
                                    somehow and as a result we're getting multiple position updates
                                    within similar chinks of elapsed time

                                    the idea here is that if any three successive calls occur closer than 10ms apart
                                    they must have been spawned from distinct requests
                                */
                                if(globalLast){
                                    const globalElapsed = timestamp - globalLast;
                                    if(globalElapsed && globalElapsed < 10) {
                                        if(fastStep){
                                            fastStep = false;
                                            return;
                                        }
                                        console.log("FAST STEP");
                                        fastStep = true;
                                    }
                                    else {
                                        fastStep = false;
                                    }
                                }
                                else{
                                    globalLast = timestamp;
                                }

                                globalLast = timestamp;

                                const pixelsToMove = (this.scrollSpeed * elapsed)/320.0;

                                // need to also account for thenext expected step to avoid skipping at the top
                                // const fullCycle = -1* (this.top - 0.025*overallHeight) > this.getSingleDataHeight();
                                const fullCycle = -1*this.top + pixelsToMove > this.getSingleDataHeight();

                                if(fullCycle){
                                    console.log("FULL CYCLE");
                                    this.top = 0;
                                    if(this.nextValues){
                                        console.log("FULL CYCLE COMPLETE (BOTH REPLACED)", this.topValues, this.bottomValues, this.nextValues);
                                        console.log("SHOULD REPLACE BOTH TOP AND BOTTOM");
                                        this.topValues = cloneDeep(this.bottomValues); // {...this.bottomValues};
                                        this.bottomValues = cloneDeep(this.nextValues); // {...this.nextValues};
                                        this.nextValues = null;
                                        this.hideBottom = true;

                                        this.checkTooSmall();
                                    }
                                    else{
                                        console.log("CYCLED, but no new values yet (TOP REPLACED)");
                                        console.log("TOP & BOTTOM", this.topValues, this.bottomValues);
                                        this.topValues = cloneDeep(this.bottomValues); // {...this.bottomValues};
                                    }
                                }
                                else{
                                    this.top -= pixelsToMove;
                                }

                                // change the vertical position
                                console.log("MAIN SCROLL TRANSFORM NON-ZERO", this.tooSmallToScroll);
                                if(!this.tooSmallToScroll){
                                    console.log("WENT THROUGH WITH NON-ZERO TRANSFORM");
                                    this.$refs.data_display.style.transform = `translateY(${this.top}px)`;
                                }
                                else{
                                    this.$refs.data_display.style.transform = `translateY(0px)`;
                                }

                                if(!lastTimeStamp){
                                    lastTimeStamp = timestamp;
                                }

                                lastTimeStamp = timestamp;

                                // if we still have the refrsh cycle going, continue
                                if(this.topTimer){
                                    makeStep();
                                }
                            });
                        };

                        // trigger the above function to start running
                        makeStep();
                    }
                });
            },
            runChangeCheck(data) {
                // use the global changes object
                const newLanes = {};
                const copy = {...data};

                console.log("RUN CHANGE CHECK", lanes, data);

                for(let i = 0; i < copy.DVTrip.length; i++){
                    // do nothing in here for now
                    const trip = copy.DVTrip[i];
                    const id = trip.internal_trip_number || trip.busid;
                    if(id){
                        newLanes[id] = {value: trip.lanegate, changed: false};
                        console.log("VIEW CHANGE", trip, trip.internal_trip_number, trip.internal_trip_number || trip.busid, id, lanes[id], lanes);
                        if(lanes[id]){
                            if((lanes[id].changed) || lanes[id].value !== trip.lanegate/* || (Math.random() > 0.9)*/){
                                if(!lanes[id]){
                                    trip.laneJustChanged = true;
                                }
                                console.log("WE HAVE A CHANGE", id);
                                trip.laneChanged = true;
                                newLanes[id].changed = true;
                            }
                        }
                    }
                }

                lanes = newLanes;
                return copy;
            },
            filterIncomplete(trips){
                const copy = [];

                trips.forEach(t => {
                    // the records need to have all of the fields
                    if(
                        t.sched_dep_time
                        && (this.isRail || t.timing_point_id)
                        && (t.public_route || t.linecode)
                        && (this.isRail || t.lanegate)
                    ){
                        copy.push(t);
                    }
                });

                return copy;
            },
            checkTooSmall(){

                // we can't check whether the display is too small without first
                // rendering something, so the check for being too small has to happen
                // asynchronously, after the initial render

                // there's a watcher on the tooSmallToScroll state variable,
                // so if it changes, we'll automatically do a redraw

                // const overallHeight = window.innerHeight;
                const overallHeight = this.$refs.data_display.parentElement.offsetHeight;

                setTimeout(() => {
                    console.log("ARE WE TOO SMALL?", this.tooSmallToScroll, this.getSingleDataHeight(), overallHeight, this.$refs.data_display.parentElement);
                    const tooSmall = this.getSingleDataHeight() < overallHeight;
                    if(tooSmall){
                        console.log("TOOOOOOO SMAAAAALL");
                        this.tooSmallToScroll = tooSmall;
                        this.topTimer = false;
                        this.hideBottom = true;

                        if(tooSmall){
                            console.log("RESET THE TRANSFORM TO ZERO 1");
                            this.$refs.data_display.style.transform = `0px`;
                        }
                    }
                    else{
                        this.previouslyScrolling = true;
                        this.hideBottom = false;
                    }
                }, 0);
            },
            navigateToStops(tripStr){
                this.clearAllIntervals();
                this.$emit("navigate-to-stops", tripStr);
            },
            clearAllIntervals(){
                clearInterval(this.topTimer);
                clearInterval(this.dataTimer);
            },
            getSingleDataHeight(){
                // get the height of the top data list only
                return this.$refs.single_data.offsetHeight + window.innerHeight * 0.02;
            }
        },
        created() {

            console.log("SCROLLING PROPS", this.$props);

            // get the scroll speed from the querystring to override the default

            // run the initial call to getData
            // and then set up an interval for regularly trying to refresh that data

            if(parseInt(this.$route.query.scroll)){
                console.log("QUERY PARAMETER SCROLL", this.$route.query.scroll);
                this.scrollSpeed = parseInt(this.$route.query.scroll);
            }

            this.getData(true);
            console.log(dataRequestInterval);
            this.dataTimer = setInterval(() => {
                this.getData();
            }, dataRequestInterval);
        },
        destroyed() {
            this.clearAllIntervals();
        }
    }
</script>

<style scoped>
    .data-container {
        width: 100%;
        height: 100%;
        margin-top: calc(0.5 * var(--mvh));
    }

    .data-display {
        position: relative;
    }

    .inner-data {
        width: 100%;
        margin-bottom: calc(2 * var(--mvh));
    }

    .very-large-screen .inner-data {
        margin-bottom: calc(0.6 * var(--mvh));
    }

    .data-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .emergency_message {

    }

    .too-small {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100 * var(--mvh));
    }

    .too-small .emergency_message {
        transform: translateY(calc(50 * var(--mvh) - 50%));
    }

    .hidden {
        visibility: hidden;
    }

    .data-timer {
        position: absolute;
        bottom: 0;
        right: 0;

        display: grid;
        place-items: center;

        width: 6vw;
        height: 6vw;

        border-radius: 50%;

        background-color: #666;

        z-index: 100;
        
        opacity: 0.5;

        margin: 0.3vw;
    }

    .data-timer::before {
        position: absolute;
        content: "Refresh";
        color: white;
        opacity: 0.5;
        font-size: 1.2vw;
    }

    .data-timer-inner {
        width: 0vw;
        height: 0vw;

        opacity: 0.8;

        border-radius: 50%;

        background-color: var(--primary-color);

        animation: fill 10s linear infinite;

        transition: 0.6s;

        z-index: 101;
    }

    .no-trips-message {
        margin-top: 20vh;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        /* margin-left: 5%; */
        padding: calc(2 * var(--mvh)) 0;
        font-weight: 600;
        font-size: 2.5rem;
        letter-spacing: 0.04rem;
    }

    @keyframes fill {
        0% {
            width: 0vw;
            height: 0vw;
        }
        100% {
            width: 6vw;
            height: 6vw;
        }
    }

    .cycle-divider {
        position: relative;
        width: 94vw;
        height: 0.15rem;
        background-color: black;
        margin: 1rem 4vw calc(1rem + 10px);
        /* box-shadow: 0 10px 0 black,
                    0 calc(0.15rem + 20px) 0 black; */
        transform: translateY(calc(10px + 0.15rem));
    }

    .cycle-divider::before {
        position: absolute;
        content: "";
        width: 92vw;
        height: 0.15rem;
        top: calc(-10px - 0.15rem);
        left: 1vw;
        background-color: black;
    }

    .cycle-divider::after {
        position: absolute;
        content: "";
        width: 92vw;
        height: 0.15rem;
        top: calc(10px + 0.15rem);
        left: 1vw;
        background-color: black;
    }
</style>