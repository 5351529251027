<template>
    <div v-if="show" @click="toggleExpansion" :class="'expansion-element ' + outerclass + ($isTabletForm ? ' smallscreen' : '') + (isFirst ? ' first': '') + ((!isFirst && isLast) ? ' last' : '') + (expandable ? ' container-expandable' : '')">
        <!--
            <div class="collapsed-content">
                <div class="inner-collapse">
                    {{ content }}
                </div>
            </div>
            <div class="expanded-content">
                <div>
                    {{ content }}
                </div>
            </div>
        -->
        <div class="after-eye">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </svg>
        </div>
        <div class="base main-content">
            <div class="main-content-inner" v-html="content">
            </div>
        </div>
        <div v-if="expandable" class="transition-caret">
            <span>
                <i class="fas fa-caret-down"></i>
            </span>
        </div>
        <div ref="collapsedShadow" :id="'collapsedShadow_' + index" :style="`--expandedHeight: ${this.expandedHeight}; --collapsedHeight: ${this.collapsedHeight};`" class="base collapsed-shadow" v-html="content">
        </div>
        <div ref="expandedShadow" :id="'expandedShadow_' + index" :style="`--expandedHeight: ${this.expandedHeight}; --collapsedHeight: ${this.collapsedHeight};`" class="base expanded-shadow">
            {{ originalContent }}
        </div>
    </div>
</template>

<script>

/*
IDEA:

    keep "shadow" versions of both expanded and collapsed text

    and show the collapsed one by default

    - on expand: get the heights of both the "shadow" versions
        - set the animation to go from collapsed height to the expanded
            - and remove the ellipses

    - on collapse: get the heights of both "shadow" versions
        - set the animation to go from the expanded height to the collapsed
            - reset the ellipses

    - also, need to add space on the side for the caret
        - rotate it between expansion/collapse
*/

    export default {
        name: "Expansion",
        props: ["message", "isEmergency", "expandByDefault", "show", "isFirst", "isLast", "index"],
        data() {
            return {
                expanded: false,
                outerclass: "default",
                content: "",
                originalContent: "",
                expandable: false                
                // min-content: "",
                // max-content: ""
            }
        },
        watch: {
            message(newVal, oldVal){
                console.log("message change in EXPANSION", newVal, oldVal);
                this.formatContent(newVal);
            },
            content(newVal, oldVal){
                console.log("message content change in EXPANSION", newVal, oldVal);
            },
            originalContent(newVal, oldVal){
                console.log("message original content change in EXPANSION", newVal, oldVal);
            },
            expandable(newVal, oldVal){
                console.log("message expandable change in EXPANSION", newVal, oldVal);
            }
        },
        mounted() {
            this.formatContent(this.$props.message);
        },
        updated() {
            // so this is supposed to run after re-rendering?
            // const cH = this.$refs.collapsedShadow.getBoundingClientRect().height;
            // const eH = this.$refs.expandedShadow.getBoundingClientRect().height;

            const cH = document.querySelector("#collapsedShadow_" + this.$props.index).getBoundingClientRect().height;
            const eH = document.querySelector("#expandedShadow_" + this.$props.index).getBoundingClientRect().height;

            console.log("MESSAGE UPDATED EXPANSION?", cH, eH);

            if(eH > cH){
                this.expandable = true;
            }
            else{
                this.expandable = false;
            }
        },
        methods: {
            toggleExpansion(){
                // const root = document.documentElement;

                // const cH = this.$refs.collapsedShadow.getBoundingClientRect().height;
                // const eH = this.$refs.expandedShadow.getBoundingClientRect().height;

                const cH = document.querySelector("#collapsedShadow_" + this.$props.index).getBoundingClientRect().height;
                const eH = document.querySelector("#expandedShadow_" + this.$props.index).getBoundingClientRect().height;

                console.log("HEIGHTS", cH, eH);

                if(this.expanded){
                    this.expanded = false;
                    this.outerclass = "collapsed";
                    // this.style.height = `${cH}px`;
                    // root.style.setProperty("--collapsed-height", `${cH}px`);
                }
                else{
                    this.expanded = true;
                    this.outerclass = "expanded";
                    // this.style.height = `${eH}px`;
                    // root.style.setProperty("--expanded-height", `${eH}px`);
                }
            },
            formatContent(val){
                let c = val;

                this.originalContent = c;
                c = c.replace("www.njtransit.com", "<a href='http://www.njtransit.com'>www.njtransit.com</a>");

                console.log("format message content", c);

                this.content = c;
            }
        }
    };

</script>

<style>
    /* :root {
        --collapsed-height: 40px;
        --expanded-height: 200px;
    } */

    .base a {
        font-size: 1.25rem;
        font-weight: 400;
        margin: 0;
    }
</style>

<style scoped>

    .main-content {
        transition: 0.3s;
    }

    .expansion-element {
        position: relative;
        cursor: default;
        /* padding: 1rem 2rem;
        width: 100vw; */
        padding: 0 0 1rem 0;
        width: 98%;
    }

    .container-expandable {
        cursor: pointer;
    }

    .expansion-element.first {
        padding: 1rem 0;
    }

    .smallscreen.expansion-element {
        padding: 0 0.25rem 1rem;
    }

    .smallscreen.expansion-element.first {
        padding: 1rem 0.25rem;
    }

    .smallscreen.expansion-element.last {
        padding: 0 0.25rem;
    }

    .after-eye {
        position: absolute;
        transform: translateY(-50%);
        top: calc(1.25rem + 1px);
        left: calc(0.55rem + 1px);
        height: 1.5rem;
        width: 1.5rem;
        color: navy;
    }

    .smallscreen .after-eye {
        top: calc(1.2rem + 1px);
        left: calc(0.75rem + 1px);
    }

    .first .after-eye {
        top: calc(2.25rem + 1px);
    }

    .smallscreen.first .after-eye {
        top: calc(2.2rem + 1px);
    }

    /*
    .expansion-element::after {
        position: absolute;
        content: "";
        top: calc(2.2rem + 1px);
        left: calc(0.5rem + 1px);
        transform: translateY(-50%);
        height: 0.75rem;
        width: 0.75rem;
        background-color: rgb(238 85 85);
        border-radius: 50%;
    }

    .smallscreen.expansion-element::after {
        top: calc(2.1rem + 1px);
        left: calc(0.75rem + 1px);
    }
    */

    .transition-caret {
        position: absolute;
        top: 0.6rem;
        right: 1rem;
        transform: scale(2);
        z-index: 10;
        cursor: pointer;
        color: #aaa;
        transition: 0.3s;
    }

    .first .transition-caret {
        top: 1.6rem;
    }

    .smallscreen .transition-caret {
        top: 0.5rem;
        right: 1.5rem;
    }

    .smallscreen.first .transition-caret {
        top: 1.5rem;
    }

    .expanded .transition-caret {
        transform: scale(2) rotate(180deg);
    }

    .base {
        overflow: hidden;
        display: flex;
        padding: 0.5rem 3rem 0.5rem 2.5rem;
        font-size: 1.25rem;
        border-radius: 0.5rem;
        border: 1px solid rgb(236 188 188);
        background-color: #eee;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
        line-height: 1.2;
    }

    .smallscreen .base {
        font-size: 1.2rem;
    }

    .collapsed-shadow, .expanded-shadow {
        width: calc(98vw - 2rem);
        visibility: hidden;
        position: absolute;
    }

    .collapsed-shadow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .expanded-shadow {

    }

    .main-content .main-content-inner {
        font-size: 1.25rem;
    }

    .smallscreen .main-content .main-content-inner {
        font-size: 1.2rem;
    }

    .default .main-content .main-content-inner {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .expanded .main-content {
        /* animation: expand 0.15s ease-in-out forwards; */
    }

    .collapsed .main-content {
        /* animation: collapse 0.25s ease-in-out forwards; */
    }

    .collapsed .main-content .main-content-inner {
        animation: collapse-inner 0.25s linear forwards;
    }

    @keyframes expand {
        0% {
            height: var(--collapsed-height);
            background-color: #eee;
            border: 1px solid rgb(236 188 188);
        }
        5% {
            height: var(--collapsed-height);
        }
        100% {
            height: var(--expanded-height);
            background-color: white;
            border: 1px solid #bbb;
        }
    }

    @keyframes collapse {
        0% {
            height: var(--expanded-height);
            background-color: white;
            border: 1px solid #bbb;     
        }
        10%{
            height: var(--expanded-height);
        }
        20% {
            height: calc(var(--expanded-height) + 0.5rem);
        }
        100% {
            height: var(--collapsed-height);
            background-color: #eee;
            border: 1px solid rgb(236 188 188);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    @keyframes collapse-inner {
        0% {

        }
        100% {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }


    /*
        ORIGINAL STYLING
    */



    .expanded-content, .collapsed-content {
        overflow: hidden;
        display: flex;
        /* transition: 3s cubic-bezier(0.25, 0.8, 0.5, 1); */
    }

    .expanded-content div {
        position: absolute;
        top: 0;
        left: 0;

        background-color: aquamarine;
        border: 1px solid black;
        padding: 1rem;
        transition: 1s;
    }

    .collapsed {
        position: relative;
    }
    
    .inner-collapse {
        background-color: bisque;
        border: 1px solid black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 1.1rem;
        padding: 0.6rem 0.4rem 0.6rem 2rem;

        background-color: #eee;
        border-radius: 0.5rem;
        border: 1px solid rgb(236 188 188);
    }

    

    .default .expanded-content {
        height: 0;
    }

    .default .collapsed-content {
        padding: 1rem;
    }

    .expanded-content {
        position: relative;
        transition: 0.3s;
    }

    .collapsed .expanded-content {
        height: 0;
    }

    .collapsed .collapsed-content {
        padding: 0.5rem 1.5rem;
        transform-origin: center;
        animation: c-expand 0.2s linear forwards;
    }

    .expanded .expanded-content {
        height: calc(85px + 2rem);
    }

    .expanded .collapsed-content {
        height: 0;
    }

    @keyframes c-expand {
        /*
        0% {
            opacity: 0;
            transform: scaleX(0.95) scaleY(0);
        }
        20% {
            opacity: 0.5;
            transform: scaleX(0.98) scaleY(0.3);
        }
        80% {
            opacity: 0.75;
            transform: scaleX(1) scaleY(0.85);
        }
        100% {
            transform: scaleX(1) scaleY(1);
        }
        */
        0% {
            padding: 0 1.5rem;
            height: 0;
        }
        100% {
            padding: 0.5rem 1.5rem;
            height: calc(3.4rem + 2px);
        }
    }

    @keyframes e-collapse {

    }

    @keyframes e-expand {

    }

</style>
