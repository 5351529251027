
For the mobile version, rather than the message being shown underneath the trips
it will be instead be shown at the top of the display 

The message will be initially collapsed to a single line within its container, 
but can be expanded with a click

This uses the vuetify v-expansion-panel to accomplish this ineteraction

<template>
    <v-expansion-panels v-model="panels" v-if="hasRoutes && !$isMobile">
        <v-expansion-panel expand v-model="panels">
            <v-expansion-panel-header>
                <span :class="'message-cutoff ' + ((pageType === 'rail') ? 'rail' : '')" style="font-weight: bold;">
                <div class="message-button">
                    <i class="fa fa-filter fa-fw"></i>
                    <span style="letter-spacing: 1.2px; font-size: 1.2rem;">
                        FILTER 
                    </span>
                    <span v-if="!$isTabletForm">
                        by 
                        {{(pageType === "rail") ? "Line" : "Route"}}
                    </span>
                </div>
                </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="filter-container">
                    <span 
                        v-for="(route, index) in routes"
                        :key="index"
                        @click="navigate(route)"
                        style="height:100%; align-self: center;"
                    >
                        <span 
                            v-if="route.type === 'rail'"
                            class="filter-button"
                            :style="`
                                background-image: url(${route.icon}); background-size: contain; background-position: center;
                            `"
                        >
                        </span>
                        <span 
                            v-else 
                            class="filter-button non-rail"
                        >
                            {{route.routeName}}
                        </span>
                    </span>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <div v-else-if="hasRoutes && $isMobile">
        <span v-if="!opened" :class="'message-cutoff ' + ((pageType === 'rail') ? 'rail' : '')" style="font-weight: bold;  transform: translateX(-8px) translateY(-1px);" @click="openPanel">
            <div class="message-button" style="text-align: center; padding: 0.4rem 3rem;">
                <i class="fa fa-filter fa-fw"></i>
                <span style="letter-spacing: 1.2px; font-size: 1.2rem;">
                    FILTER 
                </span>
                <span v-if="!$isTabletForm">
                    by 
                    {{(pageType === "rail") ? "Line" : "Route"}}
                </span>
            </div>
        </span>

        <div v-if="opened" class="mobile-panel-break" style="height: 2px; width: 100%; background-color: black;" @click="closePanel">
        </div>

        <div v-if="opened" class="filter-container">
            <span 
                v-for="(route, index) in routes"
                :key="index"
                @click="navigate(route)"
                style="height:100%; align-self: center;"
            >
                <span 
                    v-if="route.type === 'rail'"
                    class="filter-button"
                    :style="`
                        background-image: url(${route.icon}); background-size: contain; background-position: center;
                    `"
                >
                </span>
                <span 
                    v-else 
                    class="filter-button non-rail"
                >
                    {{route.routeName}}
                </span>
            </span>
        </div>

    </div>
</template>

<script>

    import { onlyNonNullValues } from "@/Utilities";

    export default {
        name: "TripFilterPanel",
        props: ["routes", "query", "pageType"],
        data() {
            return {
                panels: [],
                hasRoutes: false,
                opened: false
            }
        },
        watch: {
            routes(newVal, oldVal){
                console.log("ROUTE FILTER VALUES", newVal, oldVal);
                this.setRoutes();
            }
        },
        created(){
            this.setRoutes();
        },
        methods: {
            setRoutes(){
                this.hasRoutes = this.$props.routes.length > 0;
            },
            navigate(route){
                console.log("NAVIGATE TO ROUTE FILTER", route, this.$props.query);

                let nonTripOrStop = {...this.$props.query};
                nonTripOrStop.tripid = null;
                nonTripOrStop.route = route.routeName;
                nonTripOrStop.filtered = true

                nonTripOrStop = onlyNonNullValues(nonTripOrStop) 

                console.log("NAVIGATE TO DEPARTURES", nonTripOrStop);

                this.$router.push({
                    path: 'trips',
                    query: nonTripOrStop
                });
            },
            openPanel(){
                this.opened = true;
            },
            closePanel(){
                this.opened = false;
            }
        }
    }
</script>

<style scoped>
    .message-cutoff {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        transition: 0.3s;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        /* margin-bottom: -0.9rem; */
    }

    .rail.message-cutoff {
        justify-content: flex-end;
    }

    .mobile .message-cutoff {
        transform: translateX(6px);
    }

    .message-button {
        /* width: fit-content;  */
        width: 40%;
        background-color: white;
        border: 2px solid var(--primary-color);
        padding: 0.4rem 0.7rem;
        border-radius: 1rem;
        transition: 0.3s;

        z-index: 1;

        /* transform: translateX(-12vw); */
        /* transform: translateX(8px); */
    }

    .mobile .message-button {
        padding: 0.55rem 0.7rem;
        border-radius: 1.4rem;
        width: 100%;
        transform: none;
    }

    .message-button:hover {
        background-color: #eee;
    }

    /* .message-cutoff:hover {
        background-color: #eee;
        box-shadow: 0 0 1rem 1rem #eee;
    } */

    .v-expansion-panel:not(.v-expansion-panel--active)::before {
        box-shadow: none;
    }

    .v-expansion-panel--active .message-cutoff {
        color: transparent;
        /* background-color: rgba(0, 0, 0, 0.87); */
        border: 1px solid var(--primary-color);
        height: 2px;
        margin-bottom: -0.9rem;
    }

    .mobile-panel-break {
        position: relative;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .mobile-panel-break::before,
    .v-expansion-panel--active .message-cutoff::before {
        content: "close";
        position: absolute;
        top: calc(50% + 0.45rem);
        /* left: 50%; */
        right: calc(24px + 0.5rem);
        font-size: 0.8rem;
        font-weight: normal;
        transform: translate(-0%, -50%);
        color: black;
        /* padding: 0.2rem 0.5rem; */
        padding: 0.18rem 0.75rem 0.02rem;
        border: 1px solid var(--primary-color);
        border-radius: 1rem;
        z-index: 100;
        background-color: white;
    }

    .mobile-panel-break::before {
        top: calc(50% + 0.1rem);
        right: calc(10px);
    }

    .v-expansion-panel::before {
        box-shadow: none;
    }

    .filter-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-content: center;
        justify-items: center;
        margin-bottom: 0;
        padding: 1rem;
        gap: 0.5rem;
    }

    .mobile .filter-container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .filter-button{
        display: inline-block;
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        cursor: pointer;
    }

    .mobile .filter-button {
        width: calc(5 * var(--mvh));
        height: calc(5 * var(--mvh));
    }

    .non-rail {
        background-color: var(--primary-color);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 1rem;
    }
</style>

<style>
    .v-expansion-panel-content__wrap {
        font-size: 1.25rem;
        padding: 0 5vw 16px !important;
        /* get this to match the height of the top button */
    }

    .v-expansion-panel-content__wrap div {
        /* text-align: center; */
        font-size: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .v-expansion-panel-content__wrap svg {
        transform: scale(0.5);
    }

    .mobile .v-expansion-panel-content__wrap svg {
        transform-origin: 0 0;
    }

    button.v-expansion-panel-header--active {
        margin-top: 0.3rem;
    }
</style>