
Given the props of the level of passneger density for the given trip
show the person graphic, color-coded, as well as the text of the density level

// FULL, EMPTY, HALF_EMPTY, N/A, (null)

<template>
        <div v-if="$props.level.toLowerCase() === 'empty'" :class="horizontal ? 'layer' : 'stacked'">
            <div>
                <span class="light icon"><Person /></span>
                <span class="icon"><Person /></span>
                <span class="icon"><Person /></span>
            </div>
            <div class="text"><!-- light -->
                LIGHT
            </div>
        </div>
        <div v-else-if="$props.level.toLowerCase() === 'half_empty'" :class="horizontal ? 'layer' : 'stacked'">
            <div>
                <span class="medium icon"><Person /></span>
                <span class="medium icon"><Person /></span>
                <span class="icon"><Person /></span>
            </div>
            <div class="text"><!-- medium -->
                MEDIUM
            </div>
        </div>
        <div v-else-if="$props.level.toLowerCase() === 'full'" :class="horizontal ? 'layer' : 'stacked'">
            <div>
                <span class="heavy icon"><Person /></span>
                <span class="heavy icon"><Person /></span>
                <span class="heavy icon"><Person /></span>
            </div>
            <div class="text"><!-- heavy -->
                HEAVY
            </div>
        </div>
        <div v-else :class="horizontal ? 'layer' : 'stacked'">
            <!-- Just show nothing here instead? -->
            <!-- <div>
                <span class="icon"><Person /></span>
                <span class="icon"><Person /></span>
                <span class="icon"><Person /></span>
            </div>
            <div class="text">
                N/A
            </div> -->
        </div>
</template>

<script>
    // import { Fragment } from "vue-fragment";
    import Person from "@/components/Person";

    export default {
        name: "PassengerDensity",
        props: ["horizontal", "level"],
        components: {
            // Fragment,
            Person
        }
    }
</script>

<style scoped>
    .stacked {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 1vw;
    }

    .layer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        justify-self: center;
    }

    .text {
        font-size: 1.5em;
    }

    .mobile .text {
        font-size: 0.88rem;
    }

    .stacked .text {
        margin-left: 1vw;
    }

    .layer .text {
        margin-left: 1vw;
    }

    .light {
        color: rgb(10, 161, 10);
    }
    
    .medium {
        color: rgb(204, 167, 5);
    }

    .heavy {
        color: rgb(189, 15, 15);
    }

    .blank {
        opacity: 0.4;
    }

    .icon {
        margin-right: 0.2vw;
        font-size: 1.5rem;
    }

    .mobile .icon {
        font-size: 0.88rem;
    }

    .icon svg {
        /* font-size: 1em; */
    }
</style>