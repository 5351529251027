
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getMessaging, onMessage, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    // apiKey: "AIzaSyDYuuIVc0fX_FYivIuENUZ_Tgc7v-A2d-c",
    // authDomain: "njt-push-test.firebaseapp.com",
    // projectId: "njt-push-test",
    // storageBucket: "njt-push-test.appspot.com",
    // messagingSenderId: "109456474765",
    // appId: "1:109456474765:web:656d5f4721d3ae58cf58c4",
    // measurementId: "G-RK2NPZEEFX"
    apiKey: "AIzaSyBfDH3bKDAq8fl09dC7DTCjjIGtDFqowKI",
    authDomain: "njtransit-dd84a.firebaseapp.com",
    databaseURL: "https://njtransit-dd84a.firebaseio.com",
    projectId: "njtransit-dd84a",
    storageBucket: "njtransit-dd84a.appspot.com",
    messagingSenderId: "900611467684",
    appId: "1:900611467684:web:f4cb61daa81840cbe87931"
};

let getNewToken = () => { 
    return new Promise((resolve) => { resolve("NOT A REAL TOKEN"); }) 
};

let oml = () => { 
    console.log("NOT A REAL MESSAGE LISTENER");
    return new Promise((resolve) => { resolve({notification: {title: "FAKE", body: "ALSO FAKE"}}); }) 
};

// Initialize Firebase
if(window.Notification){ 
    const app = initializeApp(firebaseConfig);

    console.log("DO WE GET TO THIS POINT FOR LOGGING?");
    
    const messaging = getMessaging(app);
    
    // for when the app is already open?
    oml = () =>
        new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                console.log("ON MESSAGE?", Date.now, payload, resolve);
                // alert(payload.notification.title + "   " + payload.notification.body);
                // alert(payload.notification.body);
                showAlertModal(payload);
                resolve(payload);
        });
    });

    getNewToken = (key) => {
        return getToken(messaging, key);
    }
}

//
//
//
function showAlertModal(payload){

    const data = payload.data;
    const {title, body} = payload.notification;

    console.log("SHOW ALERT MODAL", data, title, body);

    let url = "";

    if(data.trainID){
        url = `https://dvtest.njtransit.com/#/rail/stops?station=${data.station}&tripid=${data.trainID}`;
    }
    else{
    url = `https://dvtest.njtransit.com/#/rail/trips?station=${data.station}`;
    }

    const div = document.createElement("div");
    div.classList.add("alert-modal");
    const closeButton = document.createElement("div");
    const content = document.createElement("div");

    closeButton.innerHTML = "X";
    closeButton.style.position = "absolute";
    closeButton.style.top = "0";
    closeButton.style.right = "0";
    closeButton.style.padding = "0.5rem";
    closeButton.style.color = "white";
    closeButton.style.backgroundColor = "black";
    closeButton.style.display = "flex";
    closeButton.style.alignItems = "center";
    closeButton.style.justifyContent = "center";
    closeButton.style.borderTopRightRadius = "1rem";
    closeButton.style.borderBottomLeftRadius = "1rem";

    content.innerHTML = body;
    content.style.padding = "2rem 1.5rem 1.5rem";
    content.style.fontSize = "1.1rem";

    div.appendChild(closeButton);
    div.appendChild(content);

    const close = () => {
        div.remove();
    }

    closeButton.addEventListener("click", (evt) => {
        close();
        evt.stopPropagation();
    });

    content.addEventListener("click", () => { openURL(url); close(); });

    const main = document.querySelector(".main");
    main.appendChild(div);
}

function openURL(url){
    window.location.href = url;
}

export const onMessageListener = oml;
export default getNewToken;
