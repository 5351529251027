
Shell for a google maps display, which will get and clear map marker data
and deal with zoom/drag interaction responses

Map data will be updated at regular intervals

<!--
    <div class="map-tester-container">
        <div style="height: 100vh; display: grid; grid-template-rows: 3fr 1fr">
            <div id="map" ref="mapRef" 
                :style="{
                        height: this.height
                    }"
            ></div>
            <div>
                <button @click="triggerGet" style="margin: 1vw; padding: 1vw; background: #ddd;">Get Locations</button>
                <button @click="triggerClear" style="margin: 1vw; padding: 1vw; background: #ddd;">Clear</button>
            </div>
        </div>
        <div style="height: 100vh; display: grid; grid-template-columns: 1fr 1fr">
            <div style="height: 100vh; overflow: scroll;">
                VEHICLE MARKERS
                {{vehicleMarkers.length}}
                <div
                    v-for="(marker, index) in vehicleMarkers"
                    v-bind:key="index"
                >
                    {{marker.id}}
                </div>
            </div>
            <div>
                STOP MARKERS
                {{stopMarkers.length}}
            </div>
        </div>
    </div>
-->

<template>
        <div id="map-parent" :style="`height: calc(${this.lineDisplay ? ($isTabletForm ? 84 : 91) : 65} * var(--mvh)); width: 100vw; position:relative; display: grid; grid-template-rows: 3fr 1fr`">
            <div id="map" ref="mapRef"
                :style="{
                        height: this.height
                    }"
                :class="fullPage ? 'full-page': ''"
            ></div>
            <div v-if="hasMap && pageType === 'rail' && !lineDisplay" class="check-overlay">
                <input id="tripCheck" type="checkbox" v-model="showTrips" />
                &nbsp;<label for="tripCheck">TRAINS</label>
                <br />
                <br />
                <input id="stopCheck" type="checkbox" v-model="showStops" />
                &nbsp;<label for="stopCheck">STATIONS</label>
            </div>
            <div v-if="hasMap && pageType === 'rail'" class="check-overlay report-legend">
                <div style="display: grid; grid-template-columns: 1fr 5fr; gap: 0.25rem; align-items: center; justify-content: space-evenly;">
                    <div style="display: inline-block; height: 1rem; width: 1rem; background-color: blue; border-radius: 50%; border: 1px solid #555;"></div>
                    <span>Police Activity</span>
                    <div style="display: inline-block; height: 1rem; width: 1rem; background-color: red; border-radius: 50%; border: 1px solid #555;"></div>
                    <span>Overcrowding</span>
                    <div style="display: inline-block; height: 1rem; width: 1rem; background-color: yellow; border-radius: 50%; border: 1px solid #555;"></div>
                    <span>Major Delay</span>
                </div>
            </div>
            <div v-if="$isMobile && hasMap" class="mobile-back-button">
                <v-btn
                    elevation="2"
                    @click="nearbyNavigateBack"
                >
                    BACK
                </v-btn>
            </div>
            <div v-if="!hasMap" id="loading-message" class="loading-message">
                Map Loading...
            </div>
        </div>
</template>

<script>
    import { getVehicleLocations, getStopAndTerminalLocations, getIndividualVehicleLocation } from "@/requests/LocationRequests";
    import RailRequests from "@/requests/RailRequests";
    import info from "@/RailPathJSON";
    import stationLabels from "@/RailStationLabels";

    import MapTextOverlay from "@/data_display/MapTextOverlay";

    console.log("WHAT IS MAP TEXT OVERLAY", MapTextOverlay);

    import { encryptTrip } from "@/Utilities";

    import RailDestinationDirection from "@/RailDestinationDirection";

    const overlayClass = MapTextOverlay();

    const getTrainPath = RailRequests.getTrainPath;
    const getLineStationLocations = RailRequests.getLineStationLocations;

    // eslint-disable-next-line no-unused-vars
    const LOCATION_INTERVAL_TIME_MS = 300000;

    // determine which things to exclude form the map display
    const stylesArray = [
    {
        "featureType": "poi.attraction",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "poi.business",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "poi.place_of_worship",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "poi.school",
        "stylers": [{"visibility": "off"}]
    }
    ];

    const minOverlayZoom = 10;

    let attempts = 0;

    let startLocation = null;

    let infowindowInterval = null;

    export default {
        name: "MapDisplay",
        props: ["currentPosition", "height", "pageType", "usingGeolocation", "geolocationDenied", "fullPage", "lineDisplay", "tripid"],
        data: () => ({
            map: null,
            hasMap: false,
            clusterer: null,
            radius: null,
            busMarkerData: [],
            stopMarkerData: [],
            markers: [],
            userPosition: null,
            userMarker: null,
            markerLookup: {},
            interval: null,
            boundsInterval: null,
            boundsChanged: true,
            isDragging: false,
            showTrips: true,
            showStops: false,
            lineDisplayCenterSet: false,
            pathDrawn: false,
            overlaysDrawn: false,
            overlays: [],
            vehicleOverlays: []
        }),
        computed: {
            vehicleMarkers: function(){
                return this.markers.filter(m => m.isVehicle).sort((a, b) => {
                    // console.log("SORTING VEHICLES", a.id, b.id);
                    return a.id < b.id;
                });
            },
            stopMarkers: function() {
                return this.markers.filter(m => !m.isVehicle).sort((a, b) => {
                    return a.id < b.id;
                });
            }
        },
        watch: {
            usingGeolocation: {
                // the callback will be called immediately after the start of the observation
                immediate: true, 
                handler (to, from) {
                    console.log("ARE WE WATCHING THE GEOLOCATION PROP NOW?");
                    if(to && !from){
                        this.initializeMap();
                    }
                }
            },
            geolocationDenied: {
                // the callback will be called immediately after the start of the observation
                immediate: true, 
                handler (to, from) {
                    console.log("GEOLOCATION DENIED STATUS CHANGED", to, from);
                    // alert("GEOLOCATION DENIED VALUE CHANGED " + to);

                    if(to && !from && this.$props.lineDisplay){
                        // alert("TRY TO INITIALIZE MAP");
                        this.initializeMap();
                    }
                }
            },
            lineDisplay(newVal, oldVal) {
                console.log("LINE DISPLAY CHANGED", newVal, oldVal);

                // I should kill any request that's already started

                this.cleanupMarkers();
                this.clearInterval();
            },
            currentPosition(newVal, oldVal){
                console.log("CHANGE OWN GEO POSITION", oldVal, newVal, window.google, window.google.map);

                if(window.google && window.google.maps){
                    console.log("CHANGE GEO CENTER POSITION", newVal.lat, newVal.lng, this.map, this.map.getCenter());
                    // this.map.setCenter(newVal.lat, newVal.lng);
                    // this.map.setCenter({lat: newVal.lat, lng:newVal.lng});
                    // this.map.setCenter(new window.google.maps.LatLng(newVal.lat, newVal.lng));
                    // this.onBoundsChanged();
                    this.createPositionMarker();
                    this.displayUserMarker();
                }
                else{
                    this.googleMapCheck();
                }
            },
            showTrips(){
                this.filterMarkers();
            },
            showStops(){
                this.filterMarkers();
            }
        },
        mounted() {
            console.log("MAP HAS BEEN MOUNTED");
            console.log("MAP PROPS", this.$props);

            // alert("COMPONENT MOUNTED " + !!(window.google && window.google.maps) + ", " + this.$props.usingGeolocation + ", " + this.$props.geolocationDenied + ", " + this.$props.lineDisplay);

            // if geolocation is turned off, then don't fire off this event,
            // especially because it will keep cycling itself indefinitely
            if(this.$props.usingGeolocation || (this.$props.geolocationDenied && this.$props.lineDisplay)){
                attempts = 0;
                this.initializeMap();
            }
            else{
                console.log("NOT USING GEOLOCATION?");
            }

            if(this.$props.pageType !== "rail"){
                this.showStops = true;
            }

            if(this.$props.lineDisplay){
                const labels = stationLabels[this.$props.lineDisplay];
                if(labels){
                    labels.forEach(l => {
                        if(l.start === "true"){
                            startLocation = l.coordinates;
                        }
                    });
                }
            }

            infowindowInterval = setInterval(() => {
                const iw = document.querySelectorAll(".gm-style-iw-a");

                // console.log("DO WE FIND ANY WINDOWS?", iw);

                for(let i = 0; i < iw.length; i++){
                    // console.log("INDIVIDUAL WINDOWS?", iw[i], iw[i].parentNode);
                    iw[i].parentNode.classList.add("infowindow-parent");
                }
            }, 1000);
        },
        methods: {
            googleMapCheck() {
                console.log("GOOGLE MAP CHECK", window.google, window.google.map);
                if(window.google && window.google.maps) {
                    console.log("CHANGE MAP POSITION, WITH MAP", this.currentPosition);
                    this.onBoundsChanged();
                }
                else{
                    console.log("GOOGLE MAP CHECK REPEAT");
                    setTimeout(this.googleMapCheck, 50);
                }
            },
            // triggerGet() {
            //     console.log("TRIGGER GET DATA");
            //     this.getData();
            // },
            // triggerClear() {
            //     console.log("TRIGGER CLEAR MARKETS");
            //     this.cleanupMarkers();
            // },
            // getData(radius) {
            getData() {

                console.log("GET VEHICLE DATA");
                console.log("GET VEHICLE DATA");
                console.log("GET VEHICLE DATA");
                console.log("GET VEHICLE DATA");
                console.log("GET VEHICLE DATA");
                console.log("GET VEHICLE DATA");
                console.log("GET VEHICLE DATA");
                console.log("GET VEHICLE DATA");

                this.createPositionMarker();

                const bounds = this.map.getBounds();
                const mapLat = bounds.getCenter().lat();
                const mapLng = bounds.getCenter().lng();

                // get the center and upper right corner of the map

                // const params = {lat, lng, radius};
                const params = {lat: mapLat, lng: mapLng, radius: this.radius};
                let iconValue = "";
                let iconBus = require("../assets/bus_icon_blue2.png");
                iconBus = `/other_map_icons/bus_icon_blue2.png`;
                const iconLightRail = require("../assets/lightrail_icon_small2.png");
                const iconRiverLine = require("../assets/lightrail_icon_small2.png");
                const iconRail = require("../assets/lightrail_icon_small2.png");

                let xOrigin = 0;

                console.log("MAP PROPS", this.$props);

                // "/bus", "/lightrail", "/riverline"
                switch(this.$props.pageType){
                    case "bus":
                        params.mode = "BUS";
                        iconValue = iconBus;
                        xOrigin = 9;
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        iconValue = iconLightRail;
                        xOrigin = 12;
                        break;
                    case "riverline":
                        params.mode = "RL";
                        iconValue = iconRiverLine;
                        break;
                    case "hblr":
                        params.mode = "RL";
                        iconValue = iconRiverLine;
                        break;
                    case "rail":
                        params.mode = "RAIL";
                        iconValue = iconRail;
                        break;
                }

                // this.cleanupMarkers();

                console.log("GET LOCATION PARAMS", params);

                let proceed = true && this.pageType !== "bus";
                // let proceed = false;

                let tempProceed = true && this.pageType !== "bus";

                // don't get the stops unless we're properly zoomed in
                if(this.map.getZoom() < 15 && this.pageType !== "rail"){
                    console.log("DON'T GET STOP DATA??");
                    // return;
                    // continue for now
                    // proceed = false;
                }

                const requests = [];

                let vehiclePromise = null;

                const individual = this.$props.lineDisplay && this.$props.tripid;

                console.log("INDIVIDUAL", individual);

                if(individual){
                    vehiclePromise = getIndividualVehicleLocation({
                        trainid: this.$props.tripid,
                        lineCode: this.$props.lineDisplay,
                        mode: "RAIL"
                    });
                    proceed = false;
                    tempProceed = false;

                    this.drawOverlays();
                }
                else{
                    vehiclePromise = getVehicleLocations(params);
                }
                
                requests.push(vehiclePromise);
                
                if(proceed){
                    const stopPromise = getStopAndTerminalLocations(params);
                    requests.push(stopPromise);
                }
                else if(tempProceed) {

                    //
                    // uhh, what exactly is the point of "proceed" here?
                    //
                    // TODO: figure out what the deal is with this here

                    console.log("GOING TO GET STOP AND TERMINAL LOCATIONS", params);
                    getStopAndTerminalLocations(params)
                    .then(({data}) => {
                        let dataToUse = data;

                        console.log("MAP DISPLAY STOP AND TERMINAL LOCATIONS", data);

                        if(this.pageType === "bus"){
                            dataToUse = data.length > 3 ? data.slice(0,3) : data;
                        }

                        this.$store.commit("setStopLocations", dataToUse);
                    })
                    .catch(err => {
                        if((err.response && err.response.status === 408) || err.code === 'ECONNABORTED'){
                            console.log("STOPS TIMEOUT", new Date(), err);
                        }
                        this.$store.commit("setStopLocations", []);
                    });
                }

                Promise.allSettled(requests)
                .then(results => {
                    console.log("LOCATION QUERY RESULTS", results);
                    if(results[0].status === "fulfilled"){
                        const { data } = results[0].value;
                        // skip this for now and see what happens
                        this.fulfillVehiclePromise(data, iconValue, xOrigin, individual);
                    }
                    else if(results[0].status === "rejected"){
                        this.$store.commit("setVehicleLocations", []);
                    }

                    if(proceed && results[1].status === "fulfilled"){
                        const { data } = results[1].value;
                        this.fulfillStopsPromise(data, xOrigin);
                    }

                    console.log("FINISHED ALL MARKERS?");

                    this.displayMarkers();
                })
                .catch(err => {
                    console.log("IS THIS A MAP TIMEOUT?");
                    if((err.response && err.response.status === 408) || err.code === 'ECONNABORTED'){
                        console.log("TIMEOUT");
                    }
                    
                });
            },
            getLineData(line) {

                console.log("GET LINE", line, getTrainPath, getLineStationLocations);

                const infoObj = JSON.parse(info);

                console.log("INFO OBJ", infoObj);

                this.drawPath(infoObj.paths, infoObj.stations[line], infoObj.lineColors[line]);

                // const promises = [getTrainPath({line}), getLineStationLocations({line})];

                // Promise.allSettled(promises)
                // .then(results => {

                //     let pathData = null;
                //     let stationData = null;

                //     if(results[0].status === "fulfilled"){
                //         console.log("GET LINE PATH DATA", results[0].value.data);
                //         pathData = results[0].value.data;
                //     }
                    
                //     if(results[1].status === "fulfilled"){
                //         console.log("GET LINE STATION DATA", results[1].value.data);
                //         stationData = results[1].value.data;
                //     }

                //     this.drawPath(pathData, stationData);
                // });
            },
            drawPath(paths, stations, lineColor){

                // const len = (paths && paths.length) ? paths.length : -1;
                // alert("DRAW PATH " + this.pathDrawn + ", " + len);

                if(this.pathDrawn){
                    return;
                }

                if(paths && paths.length){ 
                    paths.forEach(p => {
                        console.log("GET LINE DRAW PATH", p, p.coordinates);
    
                        this.individualPath(p.coordinates, "#444444", 8);

                        setTimeout(() => {
                            this.individualPath(p.coordinates, p.color, 4);
                        }, 0);
                    });
                }

                if(stations){
                    // draw the stations?
                    // just have some circles with a label?
                    stations.forEach(s => {

                        const backgroundSize = s.end ? 12 : 8 ;
                        const stationSize = s.end ? 10 : 6;

                        new window.google.maps.Marker({
                            position: s.coordinates,
                            icon: {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: backgroundSize,
                                strokeColor: "#444444",
                                fillColor: "#444444",
                                fillOpacity: 1
                            },
                            draggable: false,
                            map: this.map
                        });

                        new window.google.maps.Marker({
                            position: s.coordinates,
                            icon: {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: stationSize,
                                strokeColor: lineColor,
                                fillColor: lineColor,
                                fillOpacity: 1
                            },
                            draggable: false,
                            map: this.map
                        });
                    });
                }

                this.pathDrawn = true;
            },
            individualPath(coordinates, color, weight) {
                const pathPoly = new window.google.maps.Polyline({
                    path: coordinates,
                    geodesic: true,
                    strokeColor: color,
                    strokeOpacity: 1.0,
                    strokeWeight: weight,
                });
                
                pathPoly.setMap(this.map);
            },
            drawLocationLabels(){
                if(this.$props.lineDisplay && stationLabels){
                    const labels = stationLabels[this.$props.lineDisplay];
                    if(labels){
                        // draw these particular labels to the map
                        // call ths method from somewhere...
                        
                    }
                }
            },
            fulfillVehiclePromise(data, iconValue, xOrigin, individual){
                console.log("VEHICLE DATA", data);
                console.log("MOVING VEHICLE DATA", data);

                const markers = [];

                this.$store.commit("setVehicleLocations", data);

                // const batch = Math.floor(Math.random() * 100);

                if(individual){
                    // startLocation
                    console.log("START LOCATION FIRST", data, startLocation);
                    if(!data || data.length === 0 || !data[0].VehicleLat || !data[0].VehicleLong){
                        // set the map center
                        if(!this.lineDisplayCenterSet){
                            const center = new window.google.maps.LatLng(parseFloat(startLocation.lat), parseFloat(startLocation.lng));
                            console.log("START LOCATION?", data, startLocation, center);
    
                            // lat, lng
                            this.map.setCenter(center);
                            this.map.setZoom(10);

                            this.lineDisplayCenterSet = true;
                        }
                    }
                }

                // // clear the vehicle overlays first
                // this.clearVehicleOverlays();

                const copies = {};

                data.forEach(vehicle => {

                    const key = vehicle.VehicleID + "__" + vehicle.VehicleInternalTripNumber + "__" + vehicle.VehicleRoute + "__" + vehicle.Direction;

                    if(copies[key]){
                        console.log("COPY VEHICLE", key);
                    }
                    else{
                        copies[key] = true;
                    
                        if(this.$props.pageType === "rail") {
                            // iconValue = `/rail_icons/${vehicle.VehicleRoute}_mapicon.png`;
                            iconValue = `/line_icons/${vehicle.VehicleRoute}_icon.png`;
                        }

                        const iconSize = (this.$props.pageType === "rail") 
                                        ? (individual ? 60 : 40) 
                                        : 25;

                        const marker = {
                            position: {
                                lat: parseFloat(vehicle.VehicleLat),
                                lng: parseFloat(vehicle.VehicleLong)
                            },
                            icon: {
                                url: iconValue,
                                scaledSize: new window.google.maps.Size(iconSize, iconSize),
                                labelOrigin: {x: this.$props.pageType === "rail" ? 19 : xOrigin, y: -8}
                            },
                            // label: {
                            //     text: ((this.$props.pageType !== "rail") ? vehicle.VehicleRoute + " - #" + vehicle.VehicleID : "#" + vehicle.VehicleID), // + " " + batch,
                            //     // text: bus.VehicleID,
                            //     fontWeight: "bold",
                            //     fontSize: "14px",
                            //     border: "1px solid black",
                            //     labelClass: "label"
                            // },
                            id: vehicle.VehicleID,
                            internalNumber: vehicle.VehicleInternalTripNumber,
                            route: vehicle.VehicleRoute,
                            direction: vehicle.Direction,
                            destination: vehicle.VehicleDestination,
                            time: vehicle.VehicleScheduledDeparture,
                            isVehicle: true,
                            category: "trip",
                            reports: vehicle.reports
                        };

                        if(individual){
                            delete marker.label;
                        }

                        markers.push(marker);

                        this.busMarkerData = markers;
                        console.log("INDIVIDUAL MARKER", vehicle, vehicle.VehicleID, marker.position.lat, marker.position.lng, marker, iconValue);
                    }
                });

                console.log("MARKERS GATHERED", markers);

                if(individual && !this.lineDisplayCenterSet){
                    console.log("SET MAP CENTER?");

                    // set the center to be on the individual train
                    const v = data[0];

                    const center = new window.google.maps.LatLng(parseFloat(v.VehicleLat), parseFloat(v.VehicleLong));

                    // lat, lng, zoom
                    this.map.setCenter(center);
                    this.map.setZoom(11);

                    console.log("SET MAP CENTER FINISHED?");

                    this.lineDisplayCenterSet = true;
                }

                // try 0 for now just to clear eventloop
                setTimeout(this.processNonClusteredVehicleOverlays, 550);
            },
            processNonClusteredVehicleOverlays() {
                const overlays = [];
                const clusters = this.clusterer.getClusters();

                console.log("DO WE HAVE CLUSTERS?", clusters);

                for (let i = 0, l = clusters.length; i < l; i++) {
                    const markers = clusters[i].getMarkers();

                    console.log("do we have markers?", markers);

                    for (const m of markers) {
                        console.log("DO WE HAVE INNER MARKERS?", m, markers.length);
                        if (markers.length === 1) {
                            console.log("DO WE HAVE A MARKER TO SHOW?", m, m.position);
                            const pos = new window.google.maps.LatLng(m.position.lat(), m.position.lng());

                            console.log("CHECKPOINT INNER A");

                            let translateY = 0; 
                            
                            const fontSize = this.$isMobile ? "1rem" : this.$isTabletForm ? "0.88rem" : "1rem";

                            const minDest = m.destination ? m.destination.replace("-SEC", "").replace("&#9992", "").trim() : "";

                            console.log("MIN DEST", minDest);

                            let displayValue = "";
                            if(m.isVehicle){
                                if(this.$props.pageType === "bus"){
                                    translateY = this.$isMobile ? "-3.5rem" : this.$isTabletForm ? "-3.3rem" : "-3.5rem";
                                }
                                else{
                                    translateY = this.$isMobile ? "-5rem" : this.$isTabletForm ? "-3.6rem" : "-4.5rem";
                                }
                                displayValue = "#" + m.id;
                            }
                            else{
                                translateY = this.$isMobile ? "-4rem" : this.$isTabletForm ? "-2.5rem" : "-3.2rem";
                                displayValue = m.description;
                            }

                            let style = `transform: translate(-50%, ${translateY}); font-size: ${fontSize}; font-weight: bold;`;

                            const overlay = new overlayClass(pos, displayValue, "station-label", this.map, style, this.createReportHTML(m.reports));

                            // TODO: potentially apply report indicators here
                            //
                            // police activity: badge
                            // major delay: clock
                            // overcrowding: three people
                            //

                            overlay.setMap(this.map);
                            overlays.push(overlay);

                            if(this.$props.pageType === "rail"){
                                let direction = RailDestinationDirection[m.route][m.direction];
                            
                                if(direction){
                                    console.log("DO WE HAVE DIRECTION?", direction);
                                }

                                const directionStyle = "transform: translate(0, -100%)";
                                let directionClass = "train-direction-label ";
                                switch(direction){
                                    case "top":
                                        directionClass += " top";
                                        break;
                                    case "bottom":
                                        directionClass += " bottom";
                                        break;
                                    case "left":
                                        directionClass += " left";
                                        break;
                                    case "right":
                                        directionClass += " right";
                                        break;
                                }
                                // switch(direction){
                                //     case "up":
                                //         directionStyle += `
                                //             border-left: 10px solid transparent;
                                //             border-right: 10px solid transparent;                                        
                                //             border-bottom: 10px solid black;
                                //             transform: translate(-50%, 0);
                                //         `;
                                //         break;
                                //     case "down":
                                //         directionStyle += `
                                //             border-left: 10px solid transparent;
                                //             border-right: 10px solid transparent;
                                //             border-top: 10px solid black;
                                //             transform: translate(-50%, 0);
                                //         `;
                                //         break;
                                //     case "left":
                                //         directionStyle += `
                                //             border-top: 10px solid transparent;
                                //             border-bottom: 10px solid transparent; 
                                //             border-right:10px solid black;
                                //             transform: translate(-50%, 0);
                                //         `;
                                //         break;
                                //     case "right":
                                //         directionStyle += `
                                //             border-top: 10px solid transparent;
                                //             border-bottom: 10px solid transparent;
                                //             border-left: 10px solid black;
                                //             transform: translate(-50%, 0);
                                //         `;
                                //         break;
                                // }

                                const directionOverlay = new overlayClass(pos, "", directionClass, this.map, directionStyle);
                                directionOverlay.setMap(this.map);
                                overlays.push(directionOverlay);
                            }
                        }
                    }
                }

                console.log("VEHICLE OVERLAYS", overlays);

                this.vehicleOverlays = overlays;
            },
            createReportHTML(reports) {
                // spit out some randomly for now

                console.log("MARKER FOR REPORTS", reports);

                let isPolice = false;
                let isCrowded = false;
                let isDelayed = false;

                if(reports){
                    reports.forEach(rep => {
                        if(rep.REPORT_TEXT === "police"){
                            isPolice = true;
                        }
                        else if(rep.REPORT_TEXT === "crowd"){
                            isCrowded = true;
                        }
                        else if(rep.REPORT_TEXT === "delay"){
                            isDelayed = true;
                        }
                    });
                }

                let reportContainer = null;

                if(isPolice || isCrowded || isDelayed){
                    reportContainer = document.createElement("div");
                    reportContainer.style = "position: absolute; right: 5px; top: 0;"

                    let iconCount = 0;

                    if(isPolice){
                        reportContainer.appendChild(this.createReportIcon("police", iconCount));
                        iconCount++;
                    }

                    if(isCrowded){
                        reportContainer.appendChild(this.createReportIcon("crowd", iconCount));
                        iconCount++;
                    }

                    if(isDelayed){
                        reportContainer.appendChild(this.createReportIcon("delay", iconCount));
                        iconCount++;
                    }
                }

                return reportContainer;
            },
            createReportIcon(type, count){

                let color = "";

                switch(type){
                    case "police":
                        color = "blue";
                        break;
                    case "crowd":
                        color = "red";
                        break;
                    case "delay":
                        color = "yellow";
                        break;
                }

                let placement = "";
                if(count === 0){
                    placement = "top: -0.25rem; left: 0;"
                }
                else if(count === 1){
                    placement = "top: -0.25rem; left: calc(1rem + 2px);"
                }
                else if(count === 2){
                    placement = "top: calc(0.65rem); left: calc(0.5rem + 1px);"
                }

                const icon = document.createElement("div");
                icon.style = `position: absolute; background-color: ${color}; border: 1px solid #555; border-radius: 50%; height: 0.8rem; width: 0.8rem; ${placement}`;

                return icon;
            },
            fulfillStopsPromise(data, xOrigin){
                console.log("STOP LOCATION DATA MEMBER HERE", data);

                const markers = [];

                let dataToUse = data;

                if(this.pageType === "bus"){
                    dataToUse = data.length > 3 ? data.slice(0,3) : data;
                }

                this.$store.commit("setStopLocations", dataToUse);

                let stopIcon = null;

                const currentFullURL = window.location.href;
                const dotCom = currentFullURL.indexOf(".com");
                let path = currentFullURL.substring(0, dotCom);
                path += ".com";

                path = "";

                console.log("CURRENT FULL URL", currentFullURL);

                if(this.pageType === "bus"){
                    // stopIcon = require("../assets/bus_stop_icon_green.png");
                    stopIcon = path + "/other_map_icons/bus_stop_icon_green.png";
                }
                else if(this.pageType === "rail") {
                    // stopIcon = require("../assets/njt_circle_logo_smallicon.png");
                    stopIcon = path + "/other_map_icons/njt_circle_logo_smallicon.png";
                }

                console.log("STOPS DATA MEMBER TO USE", dataToUse, stopIcon);

                dataToUse.forEach(stop => {
                    const marker = {
                        position: {
                            lat: parseFloat(stop.latitude),
                            lng: parseFloat(stop.longitude)
                        },
                        icon: {
                            url: stopIcon,
                            labelOrigin: {x: this.$props.pageType === "rail" ? 12 : xOrigin, y: -8}
                        },
                        // label: {
                        //     text: stop.busstopdescription,
                        //     fontWeight: "bold",
                        //     fontSize: "14px",
                        //     labelClass: "label"
                        // },
                        description: stop.busstopdescription,
                        id: stop.busstopnumber,
                        isVehicle: false,
                        category: "stop",
                        reports: stop.reports
                    };
                    markers.push(marker);

                    console.log("STOPS DATA MEMBER", markers);

                    // this.stopMarkerData = markers;
                    this.stopMarkerData = markers;

                    // markers.forEach(m => {
                    //     const mapMarker = new window.google.maps.Marker(m);
                    //     mapMarker.setMap(this.map);
                    // });
                });
            },
            cleanupMarkers(doNotEmpty = false){
                console.log("CLEAR ALL MARKERS", this.markers);

                if(this.clusterer){
                    this.clusterer.clearMarkers();
                }

                this.markers.forEach(marker => {
                    this.clearMarker(marker);
                });

                if(!doNotEmpty){
                    this.markers = [];
                }
            },
            clearMarker(marker) {
                console.log("clear MARKER", marker);
                marker.setMap(null);
                window.google.maps.event.clearInstanceListeners(marker);
            },
            drawOverlays(override){
                if(!override && this.overlaysDrawn){
                    return;
                }

                this.overlaysDrawn = true;

                console.log("ALL STATION LABELS", stationLabels, stationLabels[this.lineDisplay]);

                let stations = stationLabels[this.lineDisplay];
                if(stations[0].zoomLimit){
                    stations = stations.slice(1);
                }

                const overlays = [];

                stations.forEach(s => {
                    const pos = new window.google.maps.LatLng(s.coordinates.lat, s.coordinates.lng);

                    let style = "transform: translate(0,0)";
                    
                    if(this.$isTabletForm && s.mobileTranslate){
                        style = `transform: translate(${s.mobileTranslate})`;
                    }
                    else if(s.translate){
                        style = `transform: translate(${s.translate})`;
                    }

                    const overlay = new overlayClass(pos, s.name, "station-label " + s.point, this.map, style);

                    // TODO: potentially apply report indicators here

                    overlay.setMap(this.map);
                    overlays.push(overlay);
                });

                this.overlays = overlays;
            },
            clearOverlays(){
                if(this.overlays){
                    this.overlays.forEach(o => {
                        o.setMap(null);
                    });

                    this.overlays = [];
                }
            },
            clearVehicleOverlays(){
                if(this.vehicleOverlays){
                    this.vehicleOverlays.forEach(o => {
                        o.setMap(null);
                    });

                    this.vehicleOverlays = [];
                }
            },
            displayMarkers(){
                // always clear before drawing
                // but don't clear the component's data
                // this.cleanupMarkers(true);

                if(this.clusterer){
                    this.clusterer.clearMarkers();
                }

                // clear the vehicle overlays first
                this.clearVehicleOverlays();

                console.log("DISPLAY TEST", this.map, this.map.markers);

                this.displayUserMarker();

                const newLookup = {};

                let allMarkerData =  [...this.busMarkerData, ...this.stopMarkerData];

                console.log("STOP MARKERS", this.stopMarkerData);
                // const allMarkerData =  [this.busMarkerData[1], this.busMarkerData[2]];
                console.log("DISPLAY MARKERS", allMarkerData.length, allMarkerData);

                const mapMarkers = allMarkerData.map(marker => {
                    // create marker, add to map, set event listener

                    /*
                        VehicleID: "6429"
                        VehicleLat: "40.73215103149414"
                        VehicleLong: "-74.18132019042969"
                    */

                    console.log("MOVING MARKER LOOP", marker, marker.id);

                    const match = this.markerLookup[marker.id];
                    if(match){
                        console.log("UPDATE MOVING MARKER MATCH", marker.id, marker.position.lat, marker.position.lng);
                        console.log("UPDATE PREVIOUS", marker.id, match);
                        this.updateMarkerLocation(marker, match);
                        // remove these here so they don't get cleared later 
                        delete this.markerLookup[marker.id];
                        newLookup[marker.id] = match;
                        return match;
                    }
                    else{
                        console.log("NEW MOVING MARKER", marker.id, marker.position.lat, marker.position.lng);
                        const mapMarker = new window.google.maps.Marker(marker);
                        mapMarker.setMap(this.map);
                        // window.google.maps.event.addListener(mapMarker, 'click', () => {
                        //     // nothing here yet
                        //     console.log("MARKER CLICKED", mapMarker);
                        // });

                        console.log("CREATE LINK FROM MARKER", marker);

                        let infowindow = "";

                        if(this.$props.lineDisplay){
                            infowindow = new window.google.maps.InfoWindow({
                                content: "<div style='display: flex; flex-direction: column; align-items: center; justify-content: center;'><h3>" 
                                    + "Train # " + this.$props.tripid
                                    + "</h3>"
                                    + "</div>",
                                pixelOffset: new window.google.maps.Size(0, -20)
                            });
                        }
                        else{
                            const url = this.createMarkerLink(marker);
    
                            infowindow = new window.google.maps.InfoWindow({
                                content: "<div style='display: flex; flex-direction: column; align-items: center; justify-content: center;'><h3>" 
                                    + (marker.isVehicle ? marker.destination : marker.description)
                                    + "</h3>" + url
                                    + "</div>",
                                pixelOffset: new window.google.maps.Size(0, -20)
                            });
                        }

                        console.log("MARKER WINDOW CONTENT:", infowindow.content);

                        mapMarker.addListener("click", () => {
                            infowindow.open({
                                anchor: mapMarker,
                                map: this.map,
                                shouldFocus: true
                            });
                        });

                        newLookup[marker.id] = mapMarker;
                        return mapMarker;
                    }
                });

                console.log("", this.markerLookup);

                Object.keys(this.markerLookup).forEach(key => {
                    const marker = this.markerLookup[key];
                    this.clearMarker(marker);
                });

                this.markerLookup = newLookup;
                console.log("MOVING LOOKUP", newLookup);

                // set the state, so we'll be able to refer to them later for removal
                this.markers = mapMarkers;
                console.log("CURRENT MARKERS", this.markers);

                // Add a marker clusterer to manage the markers.
                this.clusterer = new window.MarkerClusterer(this.map, this.markers, {
                    imagePath:
                    "/map_cluster_icons/m",
                });

                this.filterMarkers();
            },
            createPositionMarker() {
                if(this.$props.currentPosition) {
                    const currLat = this.$props.currentPosition.lat;
                    const currLng = this.$props.currentPosition.lng;
    
                    const position = {
                            lat: currLat,
                            lng: currLng
                        };
    
                    this.userPosition = {
                        position
                    };
                }
            },
            displayUserMarker(){
                if(this.boundsChanged){
                    if(this.userMarker){
                        this.clearMarker(this.userMarker);
                    }

                    const mapMarker = new window.google.maps.Marker(this.userPosition);

                    mapMarker.setMap(this.map);

                    this.userMarker = mapMarker;
                    this.boundsChanged = false;
                }
            },
            updateMarkerLocation(marker, mapMarker){

                console.log("MOVING UPDATE", marker, mapMarker);

                const origPos = mapMarker.getPosition();
                const origLat = origPos.lat();
                const origLng = origPos.lng();

                let newLat = marker.position.lat;
                let newLng = marker.position.lng;

                const latUnit = (newLat - origLat)/10;
                const lngUnit = (newLng - origLng)/10;

                if(latUnit == 0 && lngUnit == 0){
                    // nowhere to go
                    console.log("NO DIFFERENCE", marker.id);

                    // I think this is maybe supposed to be here?
                    var latlng = new window.google.maps.LatLng(newLat, newLng);
                    mapMarker.setPosition(latlng);

                    return;
                }

                console.log("MARKER POSITION CHECK", marker.id, origLat, origLng);

                // why is this here?
                // var latlng = new window.google.maps.LatLng(newLat, newLng);
                // mapMarker.setPosition(latlng);

                // console.log("MOVING MARKER UPADTE LATLNG", marker.id, marker, latlng, newLat, newLng, latUnit, lngUnit);

                let step = 0;
                const step_interval = setInterval(() => {
                    var latlng = new window.google.maps.LatLng(origLat + step*latUnit, origLng + step*lngUnit);
                    console.log("MOVING", newLat, newLng);
                    mapMarker.setPosition(latlng);

                    step++;
                    if(step >= 10) {
                        window.clearInterval(step_interval);
                    }
                }, 50);
            },
            createMarkerLink(marker) {
                let href= `#/${this.pageType}/${marker.isVehicle ? 'stops?' : 'trips?'}`;
                if(this.pageType === "rail"){
                    if(marker.isVehicle){
                        href += "tripid=" + marker.id + "&vid=" + marker.id;
                    }
                    else{
                        href += "station=" + marker.id;
                    }
                }
                else{
                    if(marker.isVehicle){
                        const translated = {
                            VehicleInternalTripNumber: marker.internalNumber,
                            VehicleScheduledDeparture: marker.time,
                            VehicleRoute: marker.route,
                            timing_point_id: "",
                            VehicleID: marker.id
                        }
                        console.log("LINK MARKER FOR VEHICLE", translated, marker);
                        const tripVal = encryptTrip(translated);
                        console.log("INVALID LINK ENCRYPTED?", translated, tripVal, marker);
                        href += "tripid=" + tripVal + "&vid=" + marker.id;
                    }
                    else{
                        href += "stop=" + marker.id;
                        // need to format this value?
                        href += "&stopName" + encodeURIComponent(marker.destination);
                    }
                }
                href+= "&fromMap=true";

                const link = `<a href='${href}'>See Schedule</a>`;
                return link;
            },
            getCurrentMapRadiusInKM(map) {

                const bounds = map.getBounds();

                // get the center and upper right corner of the map
                const center_lat = bounds.getCenter().lat();
                const center_lng = bounds.getCenter().lng();
                const ne_lat = bounds.getNorthEast().lat();
                const ne_lng = bounds.getNorthEast().lng();

                // r = radius of the earth in statute miles
                const earth = 3963.0;  
                const earthKM = earth * 1.609;

                const KM_TO_FEET = 3280;

                // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
                const lat1 = center_lat / 57.2958; 
                const lon1 = center_lng / 57.2958;
                const lat2 = ne_lat / 57.2958;
                const lon2 = ne_lng / 57.2958;

                // distance = circle radius from center to Northeast corner of bounds
                const distance = earthKM * Math.acos(Math.sin(lat1) * Math.sin(lat2) + 
                Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));

                return distance * KM_TO_FEET;
            },
            onZoomChanged() {
                // just use the simple approach?
                

                let minToUse = minOverlayZoom;

                if(this.lineDisplay && stationLabels[this.lineDisplay][0].zoomLimit){
                    minToUse++;
                }
                
                if(this.map.getZoom() === minToUse - 1){
                    // clear overlays
                    this.clearOverlays();
                }
                else if(this.map.getZoom() === minToUse){
                    // clear overlays
                    // draw overlays
                    this.clearOverlays();
                    this.drawOverlays(true);
                }
            },
            onBoundsChanged(){

                // called whenever the map is zoomed or dragged
                // 
                // for dragging, only run *after* the dragging has been completed

                console.log("BOUNDS CHANGED", !this.isDragging, !this.boundsInterval);

                if(!this.isDragging && !this.boundsInterval){
                    // edge case:
                    // make sure this doesn't get called again
                    // after dragging stops
                    this.boundsInterval = setInterval(() => {
                        window.clearInterval(this.boundsInterval);
                        this.boundsInterval = null;
                    }, 300);

                    console.log("BOUNDS CHANGED, NOT DRAGGING");
                    this.clearInterval();

                    const radius = Math.ceil(this.getCurrentMapRadiusInKM(this.map));

                    console.log("GET SOME DATA");

                    this.radius = radius;

                    // change the status in the Vuex store, so that
                    // we get the loading message again
                    // only need to do this for the vehicles
                    // because the stops won't change in this way
                    this.$store.commit("setVehiclesLoaded", false);

                    // if we don't have data for the stops, then do the same for the stops
                    if(!this.$store.stopLocations || !this.$store.stopLocations.length){
                        this.$store.commit("setStopsLoaded", false);
                    }

                    // get the data and then set an interval to keep updating it
                    // this.getData(radius);
                    this.getData();
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //
                    //

                    console.log("ACTUALLY CHECK HERE", this.$props.lineDisplay);
                    if(this.$props.lineDisplay){
                        this.getLineData(this.$props.lineDisplay);
                    }
                    else{
                        // TODO: restore the loading interval
                        // this.interval = window.setInterval(() => this.getData(radius), LOCATION_INTERVAL_TIME_MS);
                    }

                    console.log("CURRENT MAP ZOOM LEVEL", this.map.getZoom());

                    this.boundsChanged = true;
                }
                else{
                    console.log("DRAGGING");
                }
            },
            dragStart(){
                console.log("DRAG START");
                this.clearInterval();
                // this.cleanupMarkers();
                this.isDragging = true;
                this.markers = [];
            },
            dragEnd(){
                console.log("DRAG END");
                this.isDragging = false;
                this.onBoundsChanged();
            },
            clearInterval(){
                if(this.interval) {
                    console.log("CLEAR INTERVAL");
                    window.clearInterval(this.interval);
                }
            },
            markerClick(marker) {
                console.log("MARKER CLICKED!", marker);
            },
            filterMarkers(){
                console.log("FILTERING THE MARKERS", this.markers);
                const markersToShow = [];

                this.markers.forEach(m => {
                    if(this.showTrips && m.category === "trip" || this.showStops && m.category === "stop"){
                        m.setVisible(true);
                        markersToShow.push(m);
                    }
                    else{
                        m.setVisible(false);
                    }
                });

                if(this.clusterer){
                    this.clusterer.clearMarkers();
                    this.clusterer.addMarkers(markersToShow);
                }
            },
            initializeMap(){
                console.log("INITIALIZE MAP ATTEMPT with props", window.google, this.$props.currentPosition);

                // if the map objects have been loaded from google already then
                // proceed with initialization, otherwise set a timer to try again

                // initialization:
                // set parameters, set styles, set event listeners, 
                // set initial scale, get the data

                // this.currentPosition.lat = 40.7357
                // this.currentPosition.lng = -74.1974;
                // alert("INITIALIZE MAP" + !!(window.google && window.google.maps) + ", " + this.$props.geolocationDenied + ", " + this.$props.lineDisplay);

                if(window.google && window.google.maps && (this.$props.currentPosition || (this.$props.geolocationDenied && this.$props.lineDisplay))){

                    this.hasMap = true;

                    let positionToUse = this.$props.currentPosition;
                    if(!positionToUse){
                        // put in the NJT headquarters as a default placeholder
                        positionToUse = {"lat":40.734697,"lng":-74.164466};
                    }

                    console.log("WE HAVE THE GOOGLE MAPS OBJECT NOW", this.$props.currentPosition);

                    const options = {
                        center: positionToUse,
                        zoom: 14,
                        panControl: true,
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: true,
                        streetViewControl: false,
                        overviewMapControl: true,
                        rotateControl: false
                    }

                    console.log("INITIAL POSITION", this.$props.currentPosition);

                    if(this.$isTabletForm){
                        options.zoomControlOptions = {
                            position: window.google.maps.ControlPosition.LEFT_BOTTOM
                        }
                    }

                    if(this.pageType === "rail") {
                        stylesArray.push(
                            {
                                "featureType": "transit.station",
                                "stylers": [{"visibility": "off"}]
                            }
                        );
                    }

                    this.map = new window.google.maps.Map(this.$refs.mapRef, options);

                    // this.map = null;
                    if(!this.map){
                        return;
                    }

                    console.log("MAP OBJECT IS HERE", this.map, this.$props.currentPosition);

                    this.map.set("styles", stylesArray);

                    this.map.addListener("bounds_changed", this.onBoundsChanged);
                    this.map.addListener("center_changed", this.onBoundsChanged);
                    this.map.addListener("zoom_changed", this.onZoomChanged);
                    this.map.addListener("dragstart", this.dragStart);
                    this.map.addListener("dragend", this.dragEnd);

                    const radius = Math.ceil(this.getCurrentMapRadiusInKM(this.map));

                    this.radius = radius;

                    console.log("INITIAL GETDATA CALL", this.$props.lineDisplay);
                    // this.getData(radius);
                    if(this.$props.lineDisplay){
                        // alert("GET LINE DATA " + this.$props.lineDisplay);
                        this.getLineData(this.$props.lineDisplay);
                    }
                    
                    this.getData();
                }
                else{
                    // if we don't get it after 30 seconds, just stop trying
                    console.log("NO GOOGLE MAP YET");

                    if(attempts < 3000){
                        setTimeout(this.initializeMap, 10);
                        attempts++;
                    }
                }
            },
            nearbyNavigateBack() {
                // just do a back navigation?
                this.$router.go(-1); 
            }
        },
        beforeDestroy() {
            console.log("MAP UNMOUNTED");
            this.clearInterval();
            this.cleanupMarkers();
            attempts = 0;
            this.$store.commit("clearMapData");

            if(infowindowInterval){
                this.clearInterval(infowindowInterval);
            }
        }
    }
</script>

<style>
    :root {
        --point-color: #666;
    }

    .infowindow-parent {
        z-index: 10 !important;
    }

    .station-label {
        background: rgba(255,255,255, 0.85); 
        font-size:20px; 
        color: black; 
        width: fit-content; 
        position: absolute; 
        padding: 0.15rem 0.5rem; 
        border-radius: 1rem; 
        box-shadow: 0 0 0.1rem 0.1rem rgba(225, 225, 225, 0.75);
    }
    
    .mobile .station-label {
        font-size: 14px;
        box-shadow: 0 0 0.1rem 0.1rem rgba(225, 225, 225, 0.5);
    }

    .station-label.TL {
        border-top-left-radius:0;
    }
    .station-label.TR {
        border-top-right-radius:0;
    }
    .station-label.BL {
        border-bottom-left-radius:0;
    }
    .station-label.BR {
        border-bottom-right-radius:0;
    }
    .station-label.L {
        padding-left: 1.2rem;
    }
    .station-label.R {
        padding-right: 1.2rem;
    }

    .station-label.TL::before {
        content: "";
        position: absolute;
        top: -0.15rem;
        left: -0.48rem;

        width: 0; 
        height: 0; 
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        
        border-bottom: 0.7rem solid var(--point-color);

        transform: rotate(-45deg);
    }
    .station-label.TR::before {
        content: "";
        position: absolute;
        top: -0.2rem;
        right: -0.48rem;

        width: 0; 
        height: 0; 
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        
        border-bottom: 0.7rem solid var(--point-color);

        transform: rotate(45deg);
    }
    .station-label.BL::before {
        content: "";
        position: absolute;
        bottom: -0.25rem;
        left: -0.45rem;

        width: 0; 
        height: 0; 
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        
        border-top: 0.7rem solid var(--point-color);

        transform: rotate(45deg);
    }
    .station-label.BR::before {
        content: "";
        position: absolute;
        bottom: -0.18rem;
        right: -0.48rem;

        width: 0; 
        height: 0; 
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        
        border-top: 0.7rem solid var(--point-color);

        transform: rotate(-45deg);
    }
    .station-label.L::before {
        content: "";
        position: absolute;
        top: 35%;
        left: -0.2rem;

        width: 0; 
        height: 0; 
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
        
        border-bottom: 0.6rem solid var(--point-color);

        transform: rotate(-90deg) translateY(35%);
    }
    .station-label.R::before {
        content: "";
        position: absolute;
        top: 35%;
        right: -0.2rem;

        width: 0; 
        height: 0; 
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
        
        border-bottom: 0.6rem solid var(--point-color);

        transform: rotate(90deg) translateY(35%);
    } 

    /*
        mobile versions
    */

    .mobile .station-label.TL::before {
        transform: rotate(-45deg) scale(0.75);
    }
    .mobile .station-label.TR::before {
        transform: rotate(45deg) scale(0.75);
    }
    .mobile .station-label.BL::before {
        transform: rotate(45deg) scale(0.75);
    }
    .mobile .station-label.BR::before {
        transform: rotate(-45deg) scale(0.75);
    }
    .mobile .station-label.L::before {
        transform: rotate(-90deg) translateY(35%) scale(0.75);
    }
    .mobile .station-label.R::before {
        transform: rotate(90deg) translateY(35%) scale(0.75);
    } 

    .train-direction-label.top,
    .train-direction-label.bottom,
    .train-direction-label.left,
    .train-direction-label.right {
        position: absolute;
    }

    .train-direction-label.top::before {
        content: "";
        position: absolute;
        top: 35%;
        left: -0.2rem;

        width: 0; 
        height: 0; 
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
        
        border-bottom: 0.6rem solid black;

        transform: translateX(-35%) translateY(-900%) rotate(0deg);
    }

    .train-direction-label.bottom::before {
        content: "";
        position: absolute;
        top: 35%;
        left: -0.2rem;

        width: 0; 
        height: 0; 
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
        
        border-bottom: 0.6rem solid black;

        transform: translateX(-35%) translateY(35%) rotate(-180deg);
    }

    .train-direction-label.left::before {
        content: "";
        position: absolute;
        top: 35%;
        left: -0.2rem;

        width: 0; 
        height: 0; 
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
        
        border-bottom: 0.6rem solid black;

        transform: translateX(-200%) translateY(-300%) rotate(-90deg);
    }

    .train-direction-label.right::before {
        content: "";
        position: absolute;
        top: 35%;
        right: -0.2rem;

        width: 0; 
        height: 0; 
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
        
        border-bottom: 0.6rem solid black;

        transform:  translateX(200%) translateY(-300%) rotate(90deg);
    }

    .smallscreen .train-direction-label.top::before {
        transform: translateX(-35%) translateY(-800%) rotate(0deg);
    }

    .smallscreen .station-label.bottom::before {
        transform: translateX(-25%) translateY(50%) rotate(-180deg);
    }

    .smallscreen .train-direction-label.left::before {
        transform: translateX(-175%) translateY(-250%) rotate(-90deg);
    }

    .smallscreen .train-direction-label.right::before {
        transform:  translateX(175%) translateY(-250%) rotate(90deg);
    }

    .mobile .train-direction-label.top::before {
        transform: translateX(-25%) translateY(-1050%) scale(1.25) rotate(0deg);
    }

    .mobile .station-label.bottom::before {
        transform: translateY(35%) scale(1.25)  rotate(180deg);
    }

    .mobile .train-direction-label.left::before {
        transform: translateX(-225%) translateY(-325%) scale(1.25) rotate(-90deg);
    }

    .mobile .train-direction-label.right::before {
        transform:  translateX(225%) translateY(-325%) scale(1.25) rotate(90deg);
    }

</style>

<style scoped>
    #map {
        width:100%;
        border: 1px solid #ccc;
    }

    #map.full-page {
        /* height: 100vh !important; */
    }

    .map-tester-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .loading-message {
        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        height: calc(65 * var(--mvh));
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        font-size: 1.5rem;
        font-weight: 500;
    }

    .check-overlay {
        position: absolute;
        z-index: 100;
        top: 2vw;
        left: 2vw;
        padding: 0.25rem;
        background-color: rgba(255,255,255, 0.7);
        box-shadow: 0 0 calc(2 * var(--mvh)) rgba(255,255,255,0.6);
    }

    .report-legend {
        top: initial;
        bottom: 2vw;
        background-color: rgba(255,255,255, 0.85);
    }

    .mobile-back-button {
        position: absolute;
        top: 1.5vh;
        left: 1.5vh;
    }
</style>



                // // vehicles
                // getVehicleLocations(params)
                // .then(({data}) => {
                //     console.log("VEHICLE DATA", data);
                //     console.log("MOVING VEHICLE DATA", data);

                //     this.$store.commit("setVehicleLocations", data);

                //     const batch = Math.floor(Math.random() * 100);

                //     data.forEach(vehicle => {

                //         if(this.$props.pageType === "rail") {
                //             iconValue = `/rail_icons/${vehicle.VehicleRoute}_mapicon.png`;
                //         }

                //         const marker = {
                //             position: {
                //                 lat: parseFloat(vehicle.VehicleLat),
                //                 lng: parseFloat(vehicle.VehicleLong)
                //             },
                //             icon: {
                //                 url: iconValue,
                //                 labelOrigin: {x: this.$props.pageType === "rail" ? 12 : xOrigin, y: -8}
                //             },
                //             label: {
                //                 text: vehicle.VehicleRoute + " " + vehicle.VehicleID + " " + batch,
                //                 // text: bus.VehicleID,
                //                 fontWeight: "bold",
                //                 fontSize: "12px",
                //                 border: "1px solid black",
                //                 labelClass: "label"
                //             },
                //             id: vehicle.VehicleID,
                //             internalNumber: vehicle.VehicleInternalTripNumber,
                //             route: vehicle.VehicleRoute,
                //             destination: vehicle.VehicleDestination,
                //             time: vehicle.VehicleScheduledDeparture,
                //             isVehicle: true
                //         };
                //         markers.push(marker);

                //         console.log("INDIVIDUAL MARKER", vehicle, vehicle.VehicleID, marker.position.lat, marker.position.lng, marker, iconValue);
                //     })
                // })
                // .catch(err => console.error(err));
                


                // // stops

                // proceed && getStopAndTerminalLocations(params)
                // .then(({data}) => {

                //     console.log("STOP LOCATION DATA MEMBER HERE", data);

                //     let dataToUse = data;

                //     if(this.pageType === "bus"){
                //         dataToUse = data.length > 3 ? data.slice(0,3) : data;
                //     }

                //     this.$store.commit("setStopLocations", dataToUse);

                //     let stopIcon = null;

                //     if(this.pageType === "bus"){
                //         stopIcon = require("../assets/bus_stop_icon_green.png");
                //     }
                //     else if(this.pageType === "rail") {
                //         stopIcon = require("../assets/njt_circle_logo_smallicon.png");
                //     }

                //     dataToUse.forEach(stop => {
                //         const marker = {
                //             position: {
                //                 lat: parseFloat(stop.latitude),
                //                 lng: parseFloat(stop.longitude)
                //             },
                //             icon: {
                //                 url: stopIcon,
                //                 labelOrigin: {x: this.$props.pageType === "rail" ? 12 : xOrigin, y: -8}
                //             },
                //             label: {
                //                 text: stop.busstopdescription,
                //                 fontWeight: "bold",
                //                 fontSize: "12px",
                //                 labelClass: "label"
                //             },
                //             description: stop.busstopdescription,
                //             id: stop.busstopnumber,
                //             isVehicle: false
                //         };
                //         markers.push(marker);

                //         this.stopMarkerData = markers;
                //         console.log("VEHICLE AND STOP MARKERS", markers);
                //         this.displayMarkers();
                //     })

                //     if(!proceed){
                //         this.stopMarkerData = markers;
                //         console.log("VEHICLE MARKERS ONLY", markers);
                //         this.displayMarkers();
                //     }

                // })
                // .catch(err => console.error(err));